.burger {
    position:fixed;
    margin-top: 15px;
    margin-left: 15px;
}

#nav {
    font-size:125%;
    right:0px;
    position:fixed;
    width:100%;
    height:100%;
    background:slategray;
    color:white;
    top:55px;
    left:0;
    right:100px;
    padding:1em 0 0 2em;
    left:-120%;
    transition:left 0.2s;
    overflow-y:scroll;
}

div#nav.hamburger-true {
    visibility:visible;
    left:0;
    transition:left 0.2s;
}
div#nav.hamburger-false {

    visibility:none;
}

#inner-nav {
    height:60%;
    overflow-y:scroll; 
    
}
#nav a {
    color:white;
}

#footer {
    font-size:12pt;
    line-height:1.25em;
    width:200px;
    bottom:5em;
    position:absolute;
    right:70px;
    background:slategray;
}
